import React from "react";
import AppPrivacyPolicy from "containers/visuella/AppPrivacyPolicy";

const AppPrivacyPolicyPage = () => {
  return (
    <>
      <AppPrivacyPolicy />
    </>
  );
};
export default AppPrivacyPolicyPage;
